import PointsOfSaleServices from "@/services/PointOfSale";
import SalesAgentsServices from "@/services/SalesAgents";
import CustomersServices from "@/services/Customer";
import WarehousesServices from "@/services/Warehouse";

export default async function editPointOfSale(pos) {

  var city = await PointsOfSaleServices.getCities();
  var warehouse = await PointsOfSaleServices.getWarehouses();
  var agent = await SalesAgentsServices.getSalesAgents()
  var districts = await CustomersServices.getDistricts()
  var regions = await CustomersServices.getRegions()
  var categorization = await PointsOfSaleServices.getCategorizations()
  var users = await PointsOfSaleServices.getUsers()
  var main_unit = await WarehousesServices.getWarehouseMainUnits()
  var agents = await PointsOfSaleServices.getPosSalesAgents(pos.guid)
  var selected_main_warehouse = {}

  selected_main_warehouse[pos.main_warehouse] = pos.main_warehouse_name

  var selected_reservation_warehouse = {}
  selected_reservation_warehouse[pos.reservation_warehouse] = pos.reservation_warehouse_name

  var selected_showcase_warehouse = {}
  selected_showcase_warehouse[pos.showcase_warehouse] = pos.showcase_warehouse_name

  var selected_broken_items_warehouse = {}
  selected_broken_items_warehouse[pos.broken_items_warehouse] = pos.broken_items_warehouse_name

  var selected_product = {}
  selected_product[pos.product] = pos.product_name

  var selected_warehouses = {}
  pos.warehouses.map(w => {
    selected_warehouses[w.guid] = w.name
  })
  
  var selected_agents = [] 
  agents.map(a => {
    selected_agents[a.agent_id] = a.name
  })

  return {
    name: "Modifiko piken e shitjes",
    submit: {
      label: "Modifiko piken e shitjes",
      action: "submitPointOfSale",
    },
    fields: [
      [
        {
          value: pos.code,
          name: "code",
          label: "Kodi",
          type: "text-field",
          required: true,
          required_text: "Kjo fushe eshte e detyrueshme",
          unique: {
            model: "pointofsale",
            field: "code",
            where: [
              {
                column: "guid",
                condition: "!=",
                value: pos.guid,
              },
            ],
          },
          style: "min-width: 45%",
          left_icon: "fa-solid fa-hashtag",
        },
        {
          value: pos.name,
          name: "name",
          label: "Emri",
          type: "text-field",
          style: "min-width: 45%",
          required: true,
          required_text: "Kjo fushe eshte e detyrueshme",
        },
      ],
      [
        {
          value: pos.location,
          name: "location",
          label: "Adresa",
          type: "text-field",
          style: "min-width: 45%",
          required_text: "Kjo fushe eshte e detyrueshme",
        },
        {
          value: pos.city,
          name: "city",
          label: "Qyteti",
          type: "select-field",
          options: city,
          style: "min-width: 45%",
          required_text: "Kjo fushe eshte e detyrueshme",
        },
      ],
      [
        {
          value: pos.district,
          name: "district",
          label: "Rrethi",
          type: "select-field",
          options: districts,
          style: "min-width: 45%",
          required_text: "Kjo fushe eshte e detyrueshme",
        },
        {
          value: pos.region,
          name: "region",
          label: "Rajoni",
          type: "select-field",
          options: regions,
          style: "min-width: 45%",
          required_text: "Kjo fushe eshte e detyrueshme",
        }
      ],

      [
        {
          name: "main_warehouse",
          label: "Magazina Kryesore",
          type: "tree-select",
          options: warehouse.filter(w => w.warehouse_facility === 1),
          selected_options: selected_main_warehouse,
          style: "min-width: 43%;",
          mode: "single",
          // excluded_options: [
          //   "reservation_warehouse",
          //   "showcase_warehouse",
          //   "broken_items_warehouse",
          //   "warehouses",
          // ],
          // required: true,
        },
        {
          value: pos.main_warehouse_same_location,
          name: "main_warehouse_same_location",
          label: "",
          label_icon: "fa-solid fa-location-dot",
          type: "checkbox-field",
          required: false,
          style: "min-width: 2%;",
        },
        {
          name: "reservation_warehouse",
          label: "Magazina Rezervimit",
          type: "tree-select",
          options: warehouse.filter(w => w.warehouse_facility === 2),
          selected_options: selected_reservation_warehouse,
          style: "min-width: 43%;",
          mode: "single",
          excluded_options: [
            "main_warehouse",
            "showcase_warehouse",
            "broken_items_warehouse",
            "warehouses",
          ],
          // required: true,
        },
        {
          value: pos.reservation_warehouse_same_location,
          name: "reservation_warehouse_same_location",
          label: "",
          type: "checkbox-field",
          label_icon: "fa-solid fa-location-dot",
          required: false,
          style: "min-width: 2%;",
        }
      ],

      [
        {
          name: "showcase_warehouse",
          label: "Magazina Vitrines",
          type: "tree-select",
          options: warehouse.filter(w => w.warehouse_facility === 9),
          selected_options: selected_showcase_warehouse,
          style: "min-width: 43%;",
          mode: "single",
          // excluded_options: [
          //   "main_warehouse",
          //   "reservation_warehouse",
          //   "broken_items_warehouse",
          //   "warehouses",
          // ],
          // required: true,
        },
        {
          value: pos.showcase_warehouse_same_location,
          name: "showcase_warehouse_same_location",
          label: "",
          label_icon: "fa-solid fa-location-dot",
          type: "checkbox-field",
          required: false,
          style: "min-width: 2%;",
        },
        {
          name: "broken_items_warehouse",
          label: "Magazina P. Demtuar",
          type: "tree-select",
          options: warehouse.filter(w => w.warehouse_facility === 3),
          selected_options: selected_broken_items_warehouse,
          style: "min-width: 43%;",
          mode: "single",
          // excluded_options: [
          //   "main_warehouse",
          //   "reservation_warehouse",
          //   "showcase_warehouse",
          //   "warehouses",
          // ],
          // required: true,
        },
        {
          value: pos.broken_items_warehouse_same_location,
          name: "broken_items_warehouse_same_location",
          label: "",
          type: "checkbox-field",
          required: false,
          style: "min-width: 2%;",
          label_icon: "fa-solid fa-location-dot",
        }
      ],
      [
        {
          name: "warehouses",
          label: "Magazinat Furnizuese",
          type: "tree-select",
          options: warehouse.filter(w => w.warehouse_facility === 1),
          selected_options: selected_warehouses,
          style: "min-width: 43%",
          mode: "multiple",
          excluded_options: [
            "main_warehouse",
          ],
        },

      ],
      [
        {
          value: pos.sales_categorization,
          name: 'sales_categorization',
          label: 'Kategorizimi i shitjeve',
          type: 'select-field',
          options: categorization,
          style: 'min-width: 43%',
          required_text: 'Kjo fushe eshte e detyrueshme'
        },
        {
          value: pos.business_unit,
          name: "business_unit",
          label: "Njesia e biznesit",
          type: "select-field",
          options: main_unit,
          selected_options: [],
          style: "min-width: 43%"
        },

      ],
      [
        {
          value: pos.total_emp,
          name: 'total_emp',
          label: 'Nr i punonjesve',
          type: 'number-field',
          style: 'min-width: 43%',
          required_text: 'Kjo fushe eshte e detyrueshme'
        },
        {
          value: pos.opening_date,
          name: 'opening_date',
          label: 'Data e hapjes',
          type: 'date-field',
          style: "min-width: 45%"
        }
      ],
      [
        {
          value: pos.pos_manager,
          name: 'pos_manager',
          label: 'Menaxheri i pos',
          type: 'select-field',
          options: users,
          style: 'min-width: 43%',
          required_text: 'Kjo fushe eshte e detyrueshme'
        },
        {
          /* value: pos.sales_agents, */
          name: "sales_agents",
          label: "Agjent shitje",
          type: "tree-select",
          options: agent,
          selected_options: selected_agents,
          mode: "multiple",
          style: "min-width: 45%",
        },
      ]
    ],
  };
}

