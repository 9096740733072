import PointsOfSaleServices from "@/services/PointOfSale";
// import GenericServices from "@/services/Generic";
import SalesAgentsServices from "@/services/SalesAgents";
import CustomersServices from "@/services/Customer";
import WarehousesServices from "@/services/Warehouse";

export default async function createPointOfSale() {

  var city = await PointsOfSaleServices.getCities();
  var warehouse = await PointsOfSaleServices.getWarehouses();
  // var contact_details = await GenericServices.getDetailsTypes({ related_entity: "point_of_sale" })
  var agent = await SalesAgentsServices.getSalesAgents()
  var districts = await CustomersServices.getDistricts()
  var regions = await CustomersServices.getRegions()
  var categorization = await PointsOfSaleServices.getCategorizations()
  var users = await PointsOfSaleServices.getUsers()
  // var poses = await PointsOfSaleServices.getPointsOfSale()
  var main_unit = await WarehousesServices.getWarehouseMainUnits()

  // if(poses.length){
  //   var hash = {}
    
  //   hash['guids'] = []

  //   for(var i = 0; i < poses.length; i++){
  //     hash['main_warehouse'] = poses[i].main_warehouse
  //     hash['broken_items_warehouse'] = poses[i].broken_items_warehouse
  //     hash['reservation_warehouse'] = poses[i].reservation_warehouse
  //     hash['showcase_warehouse'] = poses[i].showcase_warehouse

  //     hash['guids'].push(hash.main_warehouse, hash.broken_items_warehouse, hash.reservation_warehouse, hash.showcase_warehouse)

  //     for(var j = 0; j < warehouse.length; j++){
  //       for (let k = 0; k < hash['guids'].length; k++) {
  //         if(warehouse[j]){
  //           if(warehouse[j].guid == hash['guids'][k]) {
  //             warehouse = [...warehouse.filter(ww => ww.guid != hash['guids'][k])]
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  return {
    name: "Krijo Pike Shitje",
    submit: {
      label: "Krijo",
      action: 'submitPointOfSale'
    },
    fields: [
      [
        {
          value: '',
          name: 'code',
          label: 'Kodi',
          type: 'text-field',
          required: true,
          style: 'min-width: 45%',
          required_text: 'Kjo fushe eshte e detyrueshme',
          unique: {
            model: 'pointofsale',
            field: 'code',
            where: []
          },
          left_icon: 'fa-solid fa-hashtag'
        },
        {
          value: '',
          name: 'name',
          label: 'Emri',
          type: 'text-field',
          style: 'min-width: 45%',
          required: true,
          required_text: 'Kjo fushe eshte e detyrueshme'
        }
      ],
      [
        {
          value: '',
          name: 'location',
          label: 'Adresa',
          type: 'text-field',
          style: 'min-width: 45%',
          required_text: 'Kjo fushe eshte e detyrueshme'
        },
        {
          name: 'city',
          label: 'Qyteti',
          type: 'select-field',
          style: 'min-width: 45%',
          options: city,
          required_text: 'Kjo fushe eshte e detyrueshme'
        },
      ],
      [
        {
          name: 'region',
          label: 'Rajoni',
          type: 'select-field',
          style: 'min-width: 45%',
          options: regions,
          required_text: 'Kjo fushe eshte e detyrueshme'
        },
        {
          name: 'district',
          label: 'Rrethi',
          type: 'select-field',
          style: 'min-width: 45%',
          options: districts,
          required_text: 'Kjo fushe eshte e detyrueshme'
        }
      ],
      /* Warehouses */
      [
        {
          name: "main_warehouse",
          label: "Magazina Kryesore",
          type: "tree-select",
          style: "min-width: 43%;",
          options: warehouse.filter(w => w.warehouse_facility === 1),
          selected_options: [],
          mode: "single",
          // required: true
        },
        {
          value: 0,
          name: "main_warehouse_same_location",
          label: "",
          label_icon: "fa-solid fa-location-dot",
          type: "checkbox-field",
          required: false,
          style: "min-width: 2%;",
        },
        {
          name: 'reservation_warehouse',
          label: "Magazina Rezervimit",
          type: "tree-select",
          options: warehouse.filter(w => w.warehouse_facility === 2),
          selected_options: [],
          style: "min-width: 43%;",
          mode: "single",
          excluded_options: [
            'main_warehouse',
            'showcase_warehouse',
            'warehouses',
            'broken_items_warehouse',
          ],
          // required: true
        },
        {
          value: 0,
          name: "reservation_warehouse_same_location",
          label: "",
          type: "checkbox-field",
          label_icon: "fa-solid fa-location-dot",
          required: false,
          style: "min-width: 2%;",
        }
      ],
      [
        {
          name: 'showcase_warehouse',
          label: "Magazina Vitrines",
          type: "tree-select",
          options: warehouse.filter(w => w.warehouse_facility === 9),
          selected_options: [],
          style: "min-width: 43%;",
          mode: "single",
          // required: true
        },
        {
          value: 0,
          name: "showcase_warehouse_same_location",
          label: "",
          style: "min-width: 2%;",
          type: "checkbox-field",
          label_icon: "fa-solid fa-location-dot",
          required: false,
        },
        {
          name: 'broken_items_warehouse',
          label: "Magazina P. Demtuar",
          type: "tree-select",
          options: warehouse.filter(w => w.warehouse_facility === 3),
          selected_options: [],
          style: "min-width: 43%;",
          mode: "single",
          // required: true
        },
        {
          value: 0,
          name: "broken_items_warehouse_same_location",
          label: "Ne Vend",
          style: "min-width: 2%;",
          type: "checkbox-field",
          label_icon: "fa-solid fa-location-dot",
          required: false,
        },
      ],
      [
        {
          name: 'warehouses',
          label: "Magazinat Furnizuese",
          type: "tree-select",
          options: warehouse.filter(w => w.warehouse_facility == 1),
          selected_options: [],
          style: "min-width: 43%",
          mode: "multiple",
          excluded_options: [
            'main_warehouse',
          ],
        },
      ],
      [
        {
          value: '',
          name: 'sales_categorization',
          label: 'Kategorizimi i shitjeve',
          type: 'select-field',
          options: categorization,
          style: 'min-width: 43%',
          required_text: 'Kjo fushe eshte e detyrueshme'
        },
        {
          name: 'business_unit',
          label: 'Njesia Kryesore',
          type: 'select-field',
          options: main_unit,
          style: 'min-width: 45%',
        },
      ],
      [
        {
          value: '',
          name: 'total_emp',
          label: 'Nr i punonjesve',
          type: 'number-field',
          style: 'min-width: 43%',
          required_text: 'Kjo fushe eshte e detyrueshme'
        },
        {
          value: '',
          name: 'opening_date',
          label: 'Data e hapjes',
          type: 'date-field',
          style: 'min-width: 45%',
        }
      ],
      [
        {
          name: 'pos_manager',
          label: 'Menaxheri i pos',
          type: 'select-field',
          options: users,
          style: 'min-width: 43%',
          required_text: 'Kjo fushe eshte e detyrueshme'
        },
        {
          /* value: pos.sales_agents, */
          name: "sales_agents",
          label: "Agjent shitje",
          type: "tree-select",
          options: agent,
          mode: "multiple",
          style: "min-width: 45%",
          selected_options: []
        },
      ],
      // [{
      //   data: [],
      //   name: 'contact_details',
      //   label: 'Kontakti',
      //   type: 'multiplexer',
      //   options: contact_details,
      //   style: 'min-width: 47%'
      // },],
    ]
  }
}

