<template>
  <div>
    <div class="columns">
      <div class="column is-flex is-flex-direction-column pb-0">
        <div class="level p-0 m-0">
          <div class="level-left">
            <grid-col-manage
              :columnDefs="columnDefs.filter(f => !['action', 'name'].includes(f.field) )"
              :hiden_columns="hiden_columns"
              @toggle_column="toggle_column"
            ></grid-col-manage>
            <div class="level-item">Pikat e shitjes</div>
            <input class="input" style="margin: 5px; width: 300px" type="text" placeholder="Kerko..."
              v-model="global_search_filter" @input="onFilterTextBoxChanged">
          </div>
          <div class="level-right">
            <div class="level">
              <a @click.prevent="openModal('warehouse')" class="pr-2 mr-2 border-r ">
                <small>
                  Magazina
                </small>
              </a>
              <a @click.prevent="openModal('cities')" class="pr-2 mr-2 border-r ">
                <small>
                  Qytete
                </small>
              </a>
              <a v-if="am_allowed('transactions.can_create_pos')" @click.prevent="create_pos">
                <i class="fa fa-plus"></i>
              </a>
            </div>
          </div>
        </div>
        <ag-grid-vue style="width:100%; min-height:40vh; height:80vh;" class="ag-theme-balham is-flex-grow-1" :columnDefs="columnDefs"
          :rowData="rowData.value" :rowSelection="rowSelection" :context="context" :localeText="localeText"
          :animateRows="true" @grid-ready="onGridReady">
        </ag-grid-vue>
      </div>
      <div class="column is-5" v-if="form_edit || form_create">
        <DaForm v-if="form_create" @submitted="submitPointOfSale" :form="form_create" @close="form_create = null" :closes_form="true"></DaForm>
        <DaForm v-if="form_edit" @submitted="submitPointOfSale" :form="form_edit" @close="form_edit = null" :closes_form="true"></DaForm>
      </div>
    </div>
  </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import DaForm from "@/components/daform/index.vue";
import ButtonsCell from "@/components/buttonscell/ButtonsCell.vue";

import createPointOfSale from "./forms/pos/create";
import editPointOfSale from "./forms/pos/edit";

import locale from "@/components/ag-grid/locale/al/al.js"
import socket from "@/socket.js";
import PointsOfSaleServices from "@/services/PointOfSale";
import permissionMixin from '@/mixins/permissions-mixin.js'
import { $vfm } from 'vue-final-modal'
import { defineAsyncComponent,onUnmounted, reactive, inject } from "vue"
import gridColManage from "@/components/grid_col_manage.vue";

export default {
  mixins: [permissionMixin],
  components: {
    AgGridVue,
    DaForm,
    gridColManage
  },
  setup() {
    let rowData = reactive([])
    const toast = inject('toast')
    socket.connect()
    socket.on("welcome", data => {
      console.log("welcome", data)
    })
    socket.on("propagate", data => {
      if(data.action == "create") {
        var a = rowData.value
        a.push(data.data)
        rowData.value = [...a]
        toast.success(
          "Pika e shitjes u krijua <i>nga " + data.created_by.name + "</i>"
        );
      }
      if(data.action == "edit") {
        rowData.value = [...rowData.value.map(e => {
          if(e.id == data.data.id) {
            return data.data
          }
          return e
        })]
        toast.success(
          "Pika e shitjes u modifikua <i>nga " + data.created_by.name + "</i>"
        );
      }
    })
    onUnmounted(() => {
      socket.off("welcome")
      socket.off("propagate")
      socket.disconnect()
    })
    return  {
      rowData
    }
  },
  beforeMount() {
    this.context = { componentParent: this };
  },
  data() {
    return {
      global_search_filter: "",
      context: null,
      selectedPointOfSale: {},
      form_create: null,
      form_edit: null,
      gridApi: null,
      defaultColDef: {
        flex: 1,
        minWidth: 100
      },
      rowSelection: "single",
      localeText: null,
      show_cities: false,
      show_warehouses: false,
      hiden_columns: [
        'total_emp',
        'agents',
        'cat_name',
        'reservation_warehouse_name',
        'showcase_warehouse_name',
        'broken_items_warehouse_name',
        'user_name',
        'opening_date',
        'warehouses_name'
      ],
    }
  },

  computed: {
    columnDefs() {
      var a = [
        { headerName: "Kodi", field: "code", filter: true, floatingFilter: true, sortable: true, resizable: true, show_with_form: true, hide: this.hiden_columns.includes("code"),},
        { headerName: "Emri", pinned: 'left', field: "name", filter: true, floatingFilter: true, sortable: true , resizable: true, show_with_form: true, hide: this.hiden_columns.includes("name"),},
        { headerName: "Adresa", field: "location", filter: true, floatingFilter: true, sortable: true , resizable: true, show_with_form: true, hide: this.hiden_columns.includes("location"),},
        { headerName: "Qyteti", field: "city_name", filter: true, floatingFilter: true, sortable: true , resizable: true, show_with_form: true, hide: this.hiden_columns.includes("city_name"),},
        { headerName: "Magazina Kryesore", field: "main_warehouse_name", filter: true,
        cellRenderer: function(params) {
          let r = ""
          if(params.data.main_warehouse == "EMPTY-STRING"){
            r = `<i class="main_warehouse">[E PAPERCAKTUAR]</i>`
          }
          else 
            r = params.data.main_warehouse_name
          return r
        },
        floatingFilter: true, sortable: true , resizable: true, show_with_form: true, hide: this.hiden_columns.includes("main_warehouse_name")},
        { headerName: "Magazina Rezervimit", field: "reservation_warehouse_name", filter: true,
        cellRenderer: function(params) {
          let r = ""
          if(params.data.reservation_warehouse == "EMPTY-STRING"){
            r = `<i class="reservation_warehouse">[E PAPERCAKTUAR]</i>`
          }
          else 
            r = params.data.reservation_warehouse_name
          return r
        },
        floatingFilter: true, sortable: true , resizable: true, show_with_form: true, hide: this.hiden_columns.includes("reservation_warehouse_name")},
        { headerName: "Magazina Vitrines", field: "showcase_warehouse_name", filter: true,
        cellRenderer: function(params) {
          let r = ""
          if(params.data.showcase_warehouse == "EMPTY-STRING"){
            r = `<i class="showcase_warehouse">[E PAPERCAKTUAR]</i>`
          }
          else 
            r = params.data.showcase_warehouse_name
          return r
        },
        floatingFilter: true, sortable: true , resizable: true, show_with_form: true, hide: this.hiden_columns.includes("showcase_warehouse_name")},
        { headerName: "Magazina P. Demtuar", field: "broken_items_warehouse_name", filter: true,
        cellRenderer: function(params) {
          let r = ""
          if(params.data.broken_items_warehouse == "EMPTY-STRING"){
            r = `<i class="broken_items_warehouse">[E PAPERCAKTUAR]</i>`
          }
          else 
            r = params.data.broken_items_warehouse_name
          return r
        },
        floatingFilter: true, sortable: true , resizable: true, show_with_form: true, hide: this.hiden_columns.includes("broken_items_warehouse_name")},
        {
          headerName: "Magazinat Furnizuese", field: "warehouses_name",
          cellRenderer: function (params) {
            let r = ""
            if (params.data.warehouses.length > 0) {
              var arr = params.data.warehouses
              for (var i = 0; i < arr.length; i++) {
                r += `<span> ${arr[i].name} </span>` 
              }
            }
            else if(params.data.warehouses.length == 0)
              r = `<i class="warehouses">[E PAPERCAKTUAR]</i>`
            return r
          }, filter: true, floatingFilter: true, sortable: true, resizable: true, show_with_form: true, hide: this.hiden_columns.includes("warehouses_name")
        },
        { headerName: "Kategorizimi i shitjeve", field: "cat_name", filter: true, floatingFilter: true, sortable: true, resizable: true, show_with_form: false, hide: this.hiden_columns.includes("cat_name"),},
        { headerName: "Nr i punonjesve", field: "total_emp", filter: true, floatingFilter: true, sortable: true, resizable: true, show_with_form: false, hide: this.hiden_columns.includes("total_emp"),},
        { headerName: "Data e hapjes", field: "opening_date", filter: true, floatingFilter: true, sortable: true, resizable: true, show_with_form: false, hide: this.hiden_columns.includes("opening_date"),},
        { headerName: "Menaxheri i pos", field: "user_name", filter: true, floatingFilter: true, sortable: true, resizable: true, show_with_form: false, hide: this.hiden_columns.includes("user_name"),},
        { headerName: "Agjentet e shitjes", field: "agents", filter: true, floatingFilter: true, sortable: true, resizable: true, show_with_form: false, hide: this.hiden_columns.includes("agents"),},
        { headerName: "Njesi biznesi", field: "unit_name", filter: true, floatingFilter: true, sortable: true, resizable: true, show_with_form: false, hide: this.hiden_columns.includes("unit_name"),},
        {
          headerName: "Action",
          cellRenderer: ButtonsCell,
          cellRendererParams: this.context,
          field: "action",
          show_with_form: true,
          pinned: 'right',
          hide: this.hiden_columns.includes("action")
        }
      ]
      if(this.form_create || this.form_edit){
        return [...a.filter(e => e.show_with_form)]
      }
      return [...a]
    }
  },
  methods: {
    toggle_column(column) {
      if (this.hiden_columns.includes(column)) {
        this.hiden_columns = [...this.hiden_columns.filter(item => item != column)]
      } else {
        this.hiden_columns.push(column)
      }

      setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
    },
    openModal(modalName) {
      $vfm.show({
        component: defineAsyncComponent(
          () => import("./components/" + modalName + "-modal.vue")
        ),
      })
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.global_search_filter)
    },
    async open(mode, pos) {
      if (mode == "1") {
        if (this.am_allowed('transactions.can_edit_pos')) {
          this.form_create = null
          if (pos) {
            setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
            this.form_edit = { loading: true }
            this.form_edit = await editPointOfSale(pos)
            this.selectedPointOfSale = pos
            setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
          }
        } else {
          this.$toast.error('Nuk keni te drejta te modifikoni pikat e shitjes.')
        }
      }
    },
    onGridReady(params) {
      this.gridApi = params.api
      this.gridApi.sizeColumnsToFit();
      this.gridColumnApi = params.columnApi
    },
    async create_pos() {
      this.form_edit = null
      setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
      this.form_create = { loading: true }
      this.form_create = await createPointOfSale()
      setTimeout(() => { this.gridApi.sizeColumnsToFit(); }, 100);
    },
    async submitPointOfSale(pos) {
      if (this.form_create != null) {
        var notify_text = ""
        let point_of_sale = await PointsOfSaleServices.createPointOfSale(pos)
        var a = this.rowData.value
        a.push(point_of_sale)
        this.rowData.value = [...a]
        socket.emit("propagate", { action: "create", data: point_of_sale })
        notify_text = "Pika e shitjes u krijua me sukses!"
        this.form_create = null
        this.$root.selected_one = {}
      }
      else {
        pos.guid = this.selectedPointOfSale.guid
        let point_of_sale = await PointsOfSaleServices.editPointOfSale(pos)
        this.rowData.value = [...this.rowData.value.map(a => {
          if (a.guid == point_of_sale.guid) a = { ...point_of_sale }
          return a
        })]
        socket.emit("propagate", { action: "edit", data: point_of_sale })
        notify_text = "Pika e shitjes u modifikua me sukses!"
        this.form_edit = null
        this.$root.selected_one = {}
      }
      setTimeout(() => {
        this.gridApi.sizeColumnsToFit();
      }, 100);
      this.$toast.success(notify_text)
    }
  },
  async created() {
    if (!this.am_allowed('transactions.can_see_pos')) {
      window.location.href = '/home'
    }
    this.localeText = locale.locale_al()
    this.rowData.value = await PointsOfSaleServices.getPointsOfSale()
    this.gridApi.sizeColumnsToFit();
  },
  onBeforeDestroy() {
        socket.disconnect();
  },
}
</script>

<style>
  .warehouses, .main_warehouse, .showcase_warehouse, .reservation_warehouse, .broken_items_warehouse {
    color: rgb(235, 8, 8);
  }
</style>
